<template>
  <div class="home-login">
    <h4 class="title">欢迎登录</h4>
    <el-form
      class="login-from"
      :model="form"
      label-position="left"
      :rules="rules"
      ref="form"
    >
      <el-form-item prop="username">
        <el-input v-model="form.username" placeholder="请输入用户手机号">
          <span class="icon-user" slot="prefix" />
        </el-input>
      </el-form-item>

      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="form.password"
          placeholder="请输入密码"
          @keyup.enter.native="login"
        >
          <span class="icon-password" slot="prefix" />
        </el-input>
      </el-form-item>
      <el-form-item>
        <Verify
          @success="verifySuccess"
          :mode="'pop'"
          :captchaType="'blockPuzzle'"
          :imgSize="{ width: '330px', height: '155px' }"
          ref="verify"
        />
      </el-form-item>
      <el-form-item>
        <el-button style="width: 100%" type="primary" @click="login"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters,mapMutations } from "vuex";
import { getUserMenuByIdentity } from "@/api/add";
import Verify from "@/components/verifition/Verify";
import { telephoneValidator } from "@/js/utils/rules";
import { UnreadMessage } from "@/api/websocket";
export default {
  components: {
    Verify,
  },
  data() {
    return {
      rules: {
        username: [
          { required: true, message: "请输入用户手机号", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { trigger: "blur", validator: telephoneValidator },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },

      form: { randomStr: "blockPuzzle" },
    };
  },
  // created(){
  //   this.getPlatformAuthentication()
  // },
  computed: mapGetters(["userInfo", "identityObj"]),
  methods: {
    // ...mapMutations(["setVipTree","SET_MESSAGE"]),
    ...mapMutations(["setVipTree","SET_MESSAGE"]),
    async UnMessage() {
      console.log("333333333333");
      let params = {
        receiverId: this.identityObj.identityId,
      };
      let res = await UnreadMessage(params);
      let AllMessages = res.data;
      this.SET_MESSAGE(AllMessages);
    },



    // 登录按钮
   async login() {
    console.log('2')
      // 用户名登录
      this.$refs.form.validate((valid) => {
        console.log(valid,'valid')
        if (valid) {
          this.$refs.verify.show();
        }
      });

    },
    async getOthersInfo() {

        let res = await getUserMenuByIdentity();

        // let IsTrue=await getPlatformAuthentication();
        // console.log(IsTrue,'测试')
        if (res.code == 0 && res.data.menuList.length > 0) {
          res.data.menuList.forEach((item) => {
            if (item.path === "memberCenter") {
              let arr = this.transMenu([item][0].children, "/memberCenter");
              if (arr.length > 0) {
                this.setVipTree(arr);
                this.$router.$avueRouter.formatRoutes(arr, true);
                if (arr[0].children.length == 0) {
                  this.$router.push({
                    path: arr[0].allPath,
                  });
                } else {
                  if (arr[0].children.length > 0) {
                    this.$router.push({
                      path: arr[0].children[0].allPath,
                    });
                  }
                }
              }
            }
          });

        }else{
            console.log('登录失败')
        }

    },
    transMenu(tree, parentName = "") {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        const fullName = parentName + "/" + node.path; // 拼接全称
        if (node.children) {
          this.transMenu(node.children, fullName); // 递归调用函数遍历子节点
        }
        node.allPath = fullName; // 赋值全称给节点
      }
      return tree;
    },
    verifySuccess(params) {
      this.form.code = params.captchaVerification;
      this.$store
        .dispatch("LoginByUsername", this.form)
        .then(() => {
          let backPath = localStorage.getItem("backPath");
          if (this.userInfo.identityList.length == 0) {
            if (this.userInfo.auditIdentity == 1) {
              this.$router.push({ name: "register1" });
            } else if (this.userInfo.auditIdentity == 2) {
              this.$router.push({ name: "register3" });
            } else if (this.userInfo.auditIdentity == 3) {
              this.$router.push({ name: "register2" });
            }
          } else if (this.userInfo.identityList.length == 1) {
            this.$store.dispatch(
              "SetIdentityObj",
              this.userInfo.identityList[0]
            );
            this.getOthersInfo();
          } else {
            this.$router.push({ name: "chooseRole" });
          }

          console.log(222222222222222);
          this.UnMessage()
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
  mounted(){

  }
};
</script>

<style lang="less" scoped>
.home-login {
  padding: 30px;

  .title {
    font-size: 26px;
    color: #fff;
    padding: 0;
    margin: 0;
    font-weight: normal;
    margin-top: 10px;
  }

  .login-from {
    margin-top: 30px;

    .icon-user,
    .icon-password {
      width: 26px;
      height: 26px;
      background-repeat: no-repeat;
      display: inline-block;
      margin: 14px 0px 0px 5px;
    }

    .icon-user {
      background-image: url("../../assets/icon_user.png");
    }

    .icon-password {
      background-image: url("../../assets/icon_password.png");
    }
  }
}
</style>

<style lang="less" >
.login-from .el-input__inner {
  height: 48px;
  background: rgba(43, 43, 43, 0.4);
  border-color: rgba(217, 217, 217, 0.2);
  color: #fff;

  &::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    color: #999;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 适配火狐 */
    color: #999;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ 适配火狐 */
    color: #999;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+  适配ie*/
    color: #999;
  }
}
</style>
